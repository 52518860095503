<template>
  <div>

    <header class="muse-header py-0 py-sm-2">
      <div class="container">
        <nav class="navbar navbar-expand-lg">
          <router-link class="navbar-brand" to="/">
            <img src="../assets/img/logo.png" width="50px" alt="Muse"> <span style="color:black">ELUAR.NET</span>
          </router-link>
          <button @click="isNavOpen = !isNavOpen" class="navbar-toggler collapsed" type="button"
            data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation">
            <svg class="menu-icon" data-name="icons/tabler/hamburger" xmlns="http://www.w3.org/2000/svg" width="20"
              height="20" viewBox="0 0 16 16">
              <rect data-name="Icons/Tabler/Hamburger background" width="16" height="16" fill="none" />
              <path
                d="M15.314,8H.686A.661.661,0,0,1,0,7.368a.653.653,0,0,1,.593-.625l.093-.006H15.314A.661.661,0,0,1,16,7.368a.653.653,0,0,1-.593.626Zm0-6.737H.686A.661.661,0,0,1,0,.632.654.654,0,0,1,.593.005L.686,0H15.314A.661.661,0,0,1,16,.632a.653.653,0,0,1-.593.625Z"
                transform="translate(0 4)" fill="#1e1e1e" />
            </svg>
            <svg class="menu-close" data-name="icons/tabler/close" xmlns="http://www.w3.org/2000/svg" width="20"
              height="20" viewBox="0 0 16 16">
              <rect data-name="Icons/Tabler/Close background" width="16" height="16" fill="none" />
              <path
                d="M.82.1l.058.05L6,5.272,11.122.151A.514.514,0,0,1,11.9.82l-.05.058L6.728,6l5.122,5.122a.514.514,0,0,1-.67.777l-.058-.05L6,6.728.878,11.849A.514.514,0,0,1,.1,11.18l.05-.058L5.272,6,.151.878A.514.514,0,0,1,.75.057Z"
                transform="translate(2 2)" fill="#1e1e1e" />
            </svg>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav" v-bind:class="{ 'show': isNavOpen }">
            <ul class="navbar-nav m-auto">
              <li class="nav-item dropdown horizontal-menu">
                <router-link to="/" class="nav-link" role="button" id="landing" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ texts.navbar_home[`display_text_${language}`] }}
                </router-link>
              </li>
              <li class="nav-item dropdown horizontal-menu" v-if="sections && sections.length > 0">
                <router-link class="nav-link" to="/sections" role="button" id="single" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ texts.navbar_sections[`display_text_${language}`] }}
                  </router-link>
                <ul class="dropdown-menu shadow-lg" aria-labelledby="single">
                  <li>
                    <ul>
                      <li>
                        <router-link class="dropdown-item" :to="'/sections/'+item.section_id"
                          v-for="(item, index) in sections" :key="index">
                          {{ item[`section_name_${language}`] }}
                        </router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown" v-if="forms && forms.length > 0">
                <a class="nav-link" href="#" role="button" id="dashboard" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ texts.navbar_froms[`display_text_${language}`] }}
                  <!-- <svg class="ms-1" data-name="icons/tabler/chevron down" xmlns="http://www.w3.org/2000/svg" width="10"
                    height="10" viewBox="0 0 16 16">
                    <rect data-name="Icons/Tabler/Chevron Down background" width="16" height="16" fill="none" />
                    <path
                      d="M.264.26A.909.909,0,0,1,1.435.174l.1.086,7.2,7.111a.881.881,0,0,1,.087,1.157l-.087.1-7.2,7.111a.908.908,0,0,1-1.273,0,.881.881,0,0,1-.087-1.157l.087-.1L6.827,8,.264,1.517A.881.881,0,0,1,.176.36Z"
                      transform="translate(16 4) rotate(90)" fill="#1e1e1e" />
                  </svg> -->
                </a>
                <ul class="dropdown-menu shadow-lg" aria-labelledby="dashboard">
                  <li v-for="(item, index) in forms" :key="index">
                    <router-link class="dropdown-item" :to="'/forms/'+item.site_form_id">
                      {{ item[`site_form_text_${language}`] }}
                    </router-link>
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <router-link to="/about" class="nav-link" role="button" id="docs" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ texts.navbar_about[`display_text_${language}`] }}
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
  export default {
    computed: {
      sections() {
        return this.$store.getters.getSections;
      },
      language() {
        return this.$store.getters.getLanguage;
      },
      texts() {
        return this.$store.getters.getTexts;
      },
      forms() {
        return this.$store.getters.getForms;
      },
    },
    data() {
      return {
        isNavOpen: false,
      }
    },
  }
</script>
<style>
  .show {
    display: block !important;
  }
  
</style>