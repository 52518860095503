<template>
  <div class="studio-template en">
    <div class="container">
      <section class="muse-section" style="height:90vh">
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="row align-items-center pb-lg-5">
          <div class="col-lg-6 col-sm-12 order-lg-2 text-lg-end" data-aos="fade-up" data-aos-delay="100">
            <img src="../assets/img/templates/studio-banner.png" class="img-fluid" alt="Banner">
          </div>
          <div class="col-lg-6 col-sm-12 mt-4 mt-lg-0">
            <h1 class="display-2">
              {{ texts.home_main_text[`display_text_${language}`] }}
            </h1>
            <h1 class=" display-6">
              {{ texts.home_main_slogan[`display_text_${language}`] }}
            </h1>
            <!-- <p class="mx-2">
              {{ texts.home_main_description[`display_text_${language}`] }}
            </p> -->
          </div>
        </div>
      </section>
      <section class="muse-section" data-aos="fade-up" data-aos-delay="100">
        
        <div class="container">
          <div class="col-lg-12 text-center">
            <h5 class="font-weight-semibold">
              {{ texts.home_section_2_title[`display_text_${language}`] }}
            </h5>
            <h2 class="h1 ">
              {{ texts.home_section_2_header[`display_text_${language}`] }}
            </h2>
          </div>
        </div>
        <br>
        <br>

        <div class="swiper-container my-5 only-small" ref="swiper">
          <div class="swiper-wrapper" ref="swiperWrapper">
            <router-link class="swiper-slide" :to="'/p/'+slide.item_id" v-for="(slide , index) in slides" :key="index">
              <div>
                <img :src="$imagePath + slide.item_image" class="rounded-12 w-100" :alt="slide.altText">
                <div class=" bottom-0 end-0 pe-3 mt-2 text-center">
                  <h6 class="mt-2 mx-auto">{{ slide[`item_title_${language}`] }}</h6>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="container only-large">
          <div class="masonry work-small-thumb">
            <div class="row">
              <div class="col-4" v-for="(slide , index) in slides" :key="index">
                <figure class="card muse-card transition-3d-hover mb-md-4 mb-0">
                  <router-link :to="'/p/'+slide.item_id" class="muse-animation"><img :src="$imagePath+slide.item_image"
                      alt="img" class="w-100 rounded-top-6"></router-link>
                  <figcaption class="p-4  text-center">

                    <router-link :to="'/p/'+slide.item_id">{{ slide[`item_title_${language}`]  }}</router-link>

                  </figcaption>
                </figure>

              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="muse-section">
        <div class="row">
          <div class="col-lg-10">
            <h6 class="font-weight-semibold">
              {{ texts.home_section_1_title[`display_text_${language}`] }}
            </h6>
            <h2>
              {{ texts.home_section_1_header[`display_text_${language}`] }}
            </h2>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-md-6 mb-4" v-for="(item, index) in partners" :key="index" data-aos="fade-up"
            data-aos-delay="100">
            <figure class="card muse-card transition-3d-hover mb-md-4 mb-0">
              <router-link :to="'/p/'+item.item_id" class="muse-animation"><img :src="$imagePath+item.item_image"
                  alt="img" class="w-100 rounded-top-6"></router-link>
              <figcaption class="p-4 mx-lg-3 mb-lg-3 position-relative">

                <h3 class="mb-md-0">
                  <router-link :to="'/p/'+item.item_id">{{ item[`item_title_${language}`]  }}</router-link>
                </h3>

              </figcaption>
            </figure>
          </div>
        </div>
      </section>
    </div>

    <div class="container">
      <section class="muse-section" data-aos="fade-up" data-aos-delay="100">
        <div class="rounded-24 overflow-hidden bg-white shadow-dark-80">
          <div class="row g-0 align-items-center bg-teal-50">
            <div class="col-lg-6 text-center p-5">
              <img src="../assets/img/templates/passion.svg" alt="img">
            </div>
            <div class="col-lg-6 bg-white">
              <div class="p-4 p-lg-5 mx-md-3 mt-2 my-lg-5">
                <h3>
                  {{ texts.home_section_3_header[`display_text_${language}`] }}
                </h3>
                <p class="big mb-4 lh-lg">
                  {{ texts.home_section_3_body[`display_text_${language}`] }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="muse-section" v-if="services.length > 0">
        <div class="row">
          <div class="col-md-7">
            <h3 class="text-uppercase pb-md-4">
              {{ texts.home_section_3_title[`display_text_${language}`] }}
            </h3>
          </div>
          <div class="col-md-5 text-md-end pt-md-2 mb-4">

          </div>
        </div>
        <div class="row">
          <div class="col-6 col-lg-3 mt-1" data-aos="fade-up" data-aos-delay="100" v-for="(item, index) in services"
            :key="index">
            <figure class="card border-0 transition-3d-hover mb-0 mb-md-3">
              <router-link :to="'/p/'+item.item_id"><img :src="$imagePath+item.item_image" class="rounded-6 w-100"
                  alt="img"></router-link>
              <figcaption class="pt-3 pb-4">
                <h5 class="mb-0 mb-md-3 title-box">
                  <router-link :to="'/p/'+item.item_id">
                    {{ item[`item_title_${language}`] }}
                  </router-link>
                </h5>
              </figcaption>
            </figure>
          </div>

        </div>
      </section>

      <section class="muse-section">
        <div class="row">
          <div class="col-lg-6 pt-0 pt-md-5 mt-0 mt-md-5 pe-md-5">
            <h6>
              {{ texts.home_contact_section_title[`display_text_${language}`] }}
            </h6>
            <h3 class="h1">
              {{ texts.home_contact_section_header[`display_text_${language}`] }}
            </h3>
            <p class="big lh-lg text-black-600 mb-4">
              {{ texts.home_contact_section_body[`display_text_${language}`] }}
            </p>
          </div>
          <div class="col-lg-6 text-center" data-aos="fade-up" data-aos-delay="100">
            <br>
            <br>
            <br>
            <img src="../assets/img/logo.png" style="max-width:300px;display:block;margin:auto" alt="">
          </div>

        </div>
      </section>
    </div>


  </div>
</template>
<script>
  export default {
    data() {
      return {


        currentPosition: 0,
        slideWidth: 0
      };
    },
    computed: {
      texts() {
        return this.$store.getters.getTexts
      },
      language() {
        return this.$store.getters.getLanguage
      },
      slides() {
        return this.$store.getters.getLast360
      },
      partners() {
        return this.$store.getters.getPartners
      },
      services() {
        return this.$store.getters.getServices
      },
    },
    mounted() {
      this.slideWidth = this.$refs.swiper.offsetWidth;
    },
    methods: {

    }
  }
</script>
<style lang="css">
  .swiper-container {
    position: relative;
    overflow: hidden;
    margin: 10px;
  }

  .swiper-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* -webkit-overflow-scrolling: auto; */
  }




  .swiper-slide {
    flex: 0 0 100%;
    scroll-snap-align: start;
    margin-right: 15px;
  }

  .swiper-slide img {
    width: 100%;
    height: auto;
  }

  /* Responsive adjustments */

  @media (min-width: 768px) {

    /* Tablets and larger screens */
    .swiper-slide {
      flex: 0 0 50%;
    }
  }

  @media (min-width: 992px) {

    /* Large screens */
    .swiper-slide {
      flex: 0 0 20%;
    }
  }

  .swiper-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  .swiper-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .swiper-wrapper::-webkit-scrollbar-track {
    background-color: transparent;
  }



  /* Next and previous buttons */
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    cursor: pointer;
    z-index: 1;
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  /* hide on tablet and up  */
  .only-small {
    display: block;
  }

  @media (min-width: 768px) {
    .only-small {
      display: none;
    }
  }

  /* hide on mobile */
  .only-large {
    display: none;
  }

  @media (min-width: 768px) {
    .only-large {
      display: block;
    }
  }
</style>