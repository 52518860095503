<template>
  <div id="app" class="en">
    <AppNavbar></AppNavbar>
    <router-view/>
    <AppFooter></AppFooter>
  </div>
</template>
<script>
import AppFooter from './components/AppFooter.vue'
import AppNavbar from './components/AppNavbar.vue'
import axios from 'axios'
export default {
  name: 'App',
  components: {
    AppFooter,
    AppNavbar
  },
  mounted() {
    this.getPublicData()
  },
  methods: {
    getPublicData(){
      axios.get('public.php')
      .then(response => {
        const displayText = response.data.display_text
        const sections = response.data.sections
        const forms = response.data.forms
        const clients = response.data.clients
        const last360 = response.data.last360
        const services = response.data.services
        const partners = response.data.partners
        // change display array text to object
        const displayObject = {}
        displayText.forEach(item => {
          displayObject[item.display_text_key ] = {
            display_text_en : item.display_text_en,
            display_text_ku : item.display_text_ku,
            display_text_ar : item.display_text_ar,
          }
        })
        this.$store.commit('setTexts', displayObject)
        this.$store.commit('setSections', sections)
        this.$store.commit('setForms', forms)
        this.$store.commit('setClients', clients)
        this.$store.commit('setLast360', last360)
        this.$store.commit('setServices', services)
        this.$store.commit('setPartners', partners)
        

      })
    }
  },
  
}
</script>
<style lang="css">
      * {
      font-family: 'Lexend', sans-serif!important;
    }


    .ku * {
      direction: rtl!important;
    }

    /* change scrollbar style */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
</style>
